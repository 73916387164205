@font-face {
  font-family: "UEFA-Regular";
  src: local("UEFA-Regular"), url("/assets/fonts/UEFA-Supercup/uefasupercup-regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "UEFA-Bold";
  src: local("UEFA-Bold"), url("/assets/fonts/UEFA-Supercup/uefasupercup-bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "UEFA-Light";
  src: local("UEFA-Light"), url("/assets/fonts/UEFA-Supercup/uefasupercup-light.ttf") format("truetype");
  font-display: swap;
}