@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~quill/dist/quill.snow.css';

body {
    margin: 0;
    height: 100vh;
}


/* Animation */
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}